import { useState } from "react";
import toast from "react-hot-toast";

import api from "@services/api";
import { Select } from "@components/utils/Select";
import { statuses } from "@constants";
import FileInput from "../../components/utils/FileInput";

import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { Mixpanel } from "../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";

import { LuFile, LuTrash2 } from "react-icons/lu";
import ReconcileModal from "./components/reconcileModal";
import PaymentModal from "./components/paymentModal";
import SendModal from "./components/sendModal";
import { useNavigate } from "react-router-dom";

const Viewer = ({ invoice }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(invoice);
  const [openSendModal, setOpenSendModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openReconcileModal, setOpenReconcileModal] = useState(false);

  const updateInvoice = async () => {
    try {
      const req = await api.put(`/invoice/${values._id}`, { ...values });
      if (!req.ok) throw req.code;
      toast.success(t("toast.sucess.invoice_updated"));
    } catch (error) {
      console.error("Error updating invoice", error);
      toast.error(t("toast.error.invoice_updating"));
    }
  };

  async function getInvoice() {
    try {
      const { data: invoiceData } = await api.get(`/invoice/${values?._id}`);
      setValues(invoiceData);
    } catch (error) {
      console.error(t("toast.error"), error);
      toast.error(error.code);
    }
  }

  if (!values) return <Loader />;

  return (
    <div className="my-4 mx-8 rounded-lg bg-white relative">
      <SendModal isOpen={openSendModal} invoice={values} setInvoice={setValues} setOpenSendModal={setOpenSendModal} setOpenPaymentModal={setOpenPaymentModal} onDone={getInvoice} />
      <PaymentModal isOpen={openPaymentModal} onClose={() => setOpenPaymentModal(false)} invoice={values} setInvoice={setValues} onUpdate={updateInvoice} />
      <ReconcileModal
        isOpen={openReconcileModal}
        invoice={values}
        onClose={() => setOpenReconcileModal(false)}
        onDone={() => {
          updateInvoice();
          getInvoice();
        }}
      />

      <div className="flex p-4 gap-8">
        <div className="flex justify-center w-full">
          <File invoice={values} />
        </div>

        <div className="w-96">
          <SideMenu
            invoice={values}
            setInvoice={setValues}
            setOpenSendModal={setOpenSendModal}
            setOpenPaymentModal={setOpenPaymentModal}
            setOpenReconcileModal={setOpenReconcileModal}
            handleSave={updateInvoice}
            getInvoice={getInvoice}
          />
        </div>
      </div>
    </div>
  );
};

const SideMenu = ({ invoice, setInvoice, setOpenSendModal, setOpenPaymentModal, setOpenReconcileModal, handleSave, getInvoice }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 mb-2">
          <div className="font-semibold">{t("status")}</div>
          <Select
            options={statuses.filter((status) => status != "DRAFT")}
            value={invoice.status}
            getLabel={(status) => (status ? t(`invoices.status.${status}`) : null)}
            onChange={(status) => {
              setInvoice({ ...invoice, status });
            }}
            placeholder={t("status")}
          />
        </div>
        <button
          className="btn-secondary w-full"
          onClick={async () => {
            await handleSave();
            Mixpanel.track(MIXPANEL_EVENTS.btn_save_invoice);
            getInvoice();
          }}>
          <span>{t("invoices.viewer.save_modifications")}</span>
        </button>
        {invoice.status !== "PAID" ? (
          <>
            <button
              className="btn-secondary w-full"
              onClick={() => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_add_payment_link_invoice);
                setOpenPaymentModal(true);
              }}>
              {t("invoices.editor.add_payment_link")}
            </button>
            <button onClick={() => setOpenSendModal(true)} className="btn-primary w-full">
              {invoice.status === "SENT" ? t("invoices.viewer.follow_up") : t("invoices.viewer.send_invoice")}
            </button>
          </>
        ) : (
          <button className="btn-primary w-full" onClick={() => setOpenReconcileModal(true)}>
            {t("invoices.viewer.reconcile_invoice")}
          </button>
        )}
      </div>
      <hr className="border-gray-300" />
      {invoice.Transactions.length > 0 && (
        <>
          <div className="flex flex-col gap-3">
            <div className="font-semibold">{t("invoices.viewer.reconciled_transactions")}</div>
            <div className="space-y-2">
              {invoice.Transactions.map((transaction) => (
                <div
                  key={transaction._id}
                  className="p-4 border border-gray-200 rounded-lg hover:border-gray-300 transition-colors cursor-pointer group"
                  onClick={() => navigate(`/transactions?id=${transaction._id}`)}>
                  <div className="flex items-center justify-between">
                    <span className={`font-medium ${transaction.amount >= 0 ? "text-green-600" : "text-red-600"}`}>
                      {transaction.amount} {transaction.currency}
                    </span>
                    <span className="text-sm text-gray-500">{new Date(transaction.date).toLocaleDateString()}</span>
                  </div>

                  <p className="mt-1.5 text-sm text-gray-600 line-clamp-2 group-hover:underline">
                    {(transaction.name || transaction.description || transaction.additionalInformation)?.length > 50
                      ? (transaction.name || transaction.description || transaction.additionalInformation).substring(0, 47) + "..."
                      : transaction.name || transaction.description || transaction.additionalInformation}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <hr className="border-gray-200" />
        </>
      )}

      <textarea
        type="text"
        value={invoice.memo ?? ""}
        onChange={(e) => setInvoice({ ...invoice, memo: e.target.value })}
        className="w-full min-h-[120px] p-3 border border-gray-200 rounded-lg 
                   text-gray-700 placeholder:text-gray-400
                   resize-none"
        placeholder={t("memo_note.to_enter.placeholder")}
        rows={5}
      />

      <div className="font-semibold">{t("invoices.editor.upload_memo_files")}</div>
      <FileInput
        onChange={async (file) => {
          setInvoice({ ...invoice, memoFiles: [file.target.value, ...(invoice.memoFiles || [])] });
          toast.success(t("toast.sucess.created"));
        }}
        name={invoice._id}
        folder={`/invoice/${invoice._id}/memoFiles`}
        width="w-full"
      />
      {invoice.memoFiles?.map((memoFile, index) => {
        return (
          <div key={memoFile._id} className="border rounded-lg p-4 relative">
            <div className="flex justify-between items-start mb-2">
              <a href={memoFile.file} target="_blank" rel="noopener noreferrer" className="text-black">
                <LuFile size={16} />
              </a>
              <button
                type="button"
                onClick={() => {
                  if (!window.confirm(t("invoices.editor.confirm_delete_memo_file"))) return;
                  setInvoice({ ...invoice, memoFiles: invoice.memoFiles.filter((_, i) => i !== index) });
                }}
                className="text-black hover:text-red-500">
                <LuTrash2 size={17} />
              </button>
            </div>
            <a href={memoFile} target="_blank" rel="noopener noreferrer" className="font-semibold text-sm hover:underline">
              {decodeURIComponent(memoFile.split("/").pop())}
            </a>
          </div>
        );
      })}
    </div>
  );
};

const File = ({ invoice }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  if (!invoice) return null;
  if (!invoice.file) return <span> {t("invoices.file.no_file")} </span>;

  return (
    <div className="w-full h-full relative">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      )}
      <object
        data={invoice.file}
        type="application/pdf"
        width="100%"
        height="100%"
        className="rounded-lg w-full h-[900px]"
        onLoad={() => setIsLoading(false)}
        onError={() => {
          setIsLoading(false);
        }}>
        <p>
          Texte alternatif - incluez un lien <a href="{{link}}">vers le PDF</a>
        </p>
      </object>
    </div>
  );
};

export default Viewer;
