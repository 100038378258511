import { Fragment, useEffect, useState } from "react";
import { Combobox as HLCombobox, Transition } from "@headlessui/react";
import { RiCloseLine, RiCheckLine, RiArrowUpSLine, RiArrowDownSLine } from "react-icons/ri";
import { LuChevronsUpDown } from "react-icons/lu";

export const Combobox = ({
  options,
  value,
  onChange,
  disabled = false,
  multiple = false,
  nullable = true,
  placeholder = "Sélectionner une option",
  width = "w-full",
  inputClassName = "",
  containerClassName = "rounded-md border-gray-300",
  position = "left-0",
  listHeader,
  getValue = (o) => o,
  getLabel = (o) => o,
  by = (a, b) => getValue(a) === getValue(b),
  match = (o, s) => getLabel(o).toLowerCase().includes(s.toLowerCase()),
  displayValue = (o) => (multiple && o[0] ? getLabel(o[0]) + (o.length > 1 ? ` +${o.length - 1} ` : "") : getLabel(o)),
}) => {
  const [filteredOptions, setFilteredOptions] = useState(options);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleInputChange = (e) => {
    const value = e.target.value;
    const filteredOptions = options.filter((option) => match(option, value));
    setFilteredOptions(filteredOptions);
  };

  return (
    <HLCombobox value={value || ""} onChange={onChange} disabled={disabled} by={by} multiple={multiple}>
      {({ open }) => (
        <>
          <div className="relative w-full">
            <div className={`w-full flex gap-4 items-center justify-between bg-white border overflow-hidden hover:border-sky-700 ${containerClassName}`}>
              <HLCombobox.Button
                className="w-full"
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                <HLCombobox.Input
                  className={`${inputClassName} w-full border-none flex-1 text-left placeholder:text-gray-600 py-1 px-3`}
                  displayValue={displayValue}
                  onChange={handleInputChange}
                  placeholder={placeholder}
                />
                {((multiple && value.length > 0) || (!multiple && value)) && !disabled && nullable ? (
                  <div className="h-full absolute top-0 right-0">
                    <div
                      className={`absolute inset-y-0 right-0 flex items-center pr-2 ${disabled ? "opacity-50" : ""}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        onChange(multiple ? [] : "");
                      }}>
                      <RiCloseLine />
                    </div>
                  </div>
                ) : (
                  <div className={`top-0 absolute inset-y-0 right-0 flex items-center px-2 ${disabled ? "opacity-50" : ""}`}>
                    {open ? <RiArrowUpSLine /> : <RiArrowDownSLine />}
                  </div>
                )}
              </HLCombobox.Button>
            </div>

            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <HLCombobox.Options
                className={`absolute z-30 my-1 max-h-64 ${width} ${position} overflow-y-auto border border-gray-300 rounded-md bg-white divide-y divide-gray-200`}>
                {listHeader}

                {filteredOptions.length === 0 ? (
                  <HLCombobox.Option className="px-3 py-2 flex items-center justify-between">
                    <p className={`block truncate text-xs text-gray-400`}>No results</p>
                  </HLCombobox.Option>
                ) : (
                  filteredOptions.map((option, i) => (
                    <HLCombobox.Option key={i} value={option} className="select-none list-none cursor-pointer" onClick={(e) => e.stopPropagation()}>
                      {({ active, selected }) => (
                        <div className={`${active ? "bg-gray-100" : "bg-transparent"} px-3 py-2 flex items-center justify-between`} title={getLabel(option)}>
                          <p className={`block truncate text-xs ${selected ? "font-semibold" : "font-normal"}`}> {getLabel(option)} </p>
                          {selected && <RiCheckLine />}
                        </div>
                      )}
                    </HLCombobox.Option>
                  ))
                )}
              </HLCombobox.Options>
            </Transition>
          </div>
        </>
      )}
    </HLCombobox>
  );
};
