import { useEffect, useState } from "react";
import api from "@services/api";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { Mixpanel } from "@services/mixpanel";
import { MIXPANEL_EVENTS } from "../../utils/mixpanelEvents";
import { setOrganization } from "@redux/auth/actions";
import { copyToClipboard } from "@utils";
import { ENVIRONMENT } from "@config";
import { FaLink, FaArrowRight, FaBookOpen } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import Modal from "@components/utils/Modal";
import ReferralsTable from "./components/table";

const List = () => {
  const organization = useSelector((state) => state.Auth.organization);
  const user = useSelector((state) => state.Auth.user);
  const [referrals, setReferrals] = useState([]);
  const [open, setOpen] = useState(!organization.referralCGVAcceptedAt);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const redirectUrl = ENVIRONMENT === "development" ? "http://localhost:8082" : "https://app.finotor.com";

  const copyAffiliateLink = () => {
    copyToClipboard(`${redirectUrl}/auth/signup?referrerCode=${organization.referralCode}&lang=${user.language.toLowerCase()}`);
    toast.success(t("promo_code.copy_to_clipboard"));
    Mixpanel.track(MIXPANEL_EVENTS.btn_copy_referral_link);
  };

  const getReferrals = async () => {
    try {
      const { data } = await api.post(`/organization/referrals`, {
        OrganizationId: organization._id,
      });

      setReferrals(data.mergedReferrals);
    } catch (error) {
      toast.error(t("promo_code.error_referrals"));
      console.error(error);
    }
  };

  const acceptReferralCGV = async () => {
    if (organization.referralCGVAcceptedAt) {
      setOpen(false);
      return;
    }
    try {
      const { ok, data } = await api.put(`/organization/${organization._id}`, {
        referralCGVAcceptedAt: new Date(),
      });
      if (!ok) {
        toast.error(t("promo_code.error_accept_cgv"));
        return;
      }
      dispatch(setOrganization(data));
      toast.success(t("promo_code.success_accept_cgv"));
    } catch (error) {
      toast.error(t("promo_code.error_accept_cgv"));
      console.error(error);
    } finally {
      setOpen(false);
    }
  };

  useEffect(() => {
    Mixpanel.track(MIXPANEL_EVENTS.page_view_referral_page);
    getReferrals();
    setOpen(!organization.referralCGVAcceptedAt);
  }, [organization]);

  if (!organization.referralCGVAcceptedAt) {
    return (
      <div className="mx-auto">
        <div className="py-6 px-6">
          <div className="flex flex-col bg-white rounded-xl p-6">
            <div className="flex justify-between items-start">
              <div>
                <h1 className="text-2xl font-bold">{t("promo_code.title")}</h1>
                <p className="mt-2 text-slate-600">{t("promo_code.description")}</p>
              </div>
              <div className="flex flex-col gap-2">
                <button className="btn-secondary w-fit mt-4" onClick={() => setOpen(true)}>
                  {t("promo_code.button_open_cgv")}
                </button>
              </div>
              <ReferralCGVModal open={open} setOpen={setOpen} onClick={acceptReferralCGV} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto">
      <div className="py-6 px-6">
        <div className="flex flex-col bg-white rounded-xl p-6">
          <div className="flex justify-between items-start">
            <div>
              <h1 className="text-2xl font-bold">{t("promo_code.title")}</h1>
              <p className="mt-2 text-slate-600">{t("promo_code.description")}</p>
            </div>
            <div className="flex gap-2">
              <button
                className="btn-secondary focus:bg-blue float-right mb-2"
                onClick={() => window.open("https://blue-shape-2ae.notion.site/Espace-Affili-s-de-Finotor-FR-127bbfd511cc80b2bbe2f9453762a9bc?pvs=4", "_blank")}>
                <FaBookOpen className="mr-2" />
                {t("promo_code.documentation_link")}
              </button>
              <button className="btn-primary focus:bg-blue float-right mb-2" onClick={() => copyAffiliateLink()}>
                <FaLink className="mr-2" />
                {t("promo_code.share_link")}
              </button>
            </div>
          </div>
          <div className="mt-6 flex flex-col space-y-6">
            <span className="text-xl text-slate-700 font-semibold">{t("promo_code.rules")}</span>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 justify-items-center">
              <div className="flex flex-col bg-[#BBF7D0] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.direct")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.starter")}</span> {t("promo_code.rules.direct.starter_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.lite")}</span> {t("promo_code.rules.direct.lite_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.premium")}</span> {t("promo_code.rules.direct.premium_description")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col bg-[#FED7AA] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.indirect")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.lite")}</span> {t("promo_code.rules.indirect.lite_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.premium")}</span> {t("promo_code.rules.indirect.premium_description")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col bg-[#E9D5FF] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.extended")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.lite")}</span> {t("promo_code.rules.extended.lite_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.premium")}</span> {t("promo_code.rules.extended.premium_description")}
                  </p>
                </div>
              </div>
              <div className="flex flex-col bg-[#F3F6FF] max-w-sm px-5 py-4 gap-2 rounded-lg shadow-sm">
                <h3 className="text-lg font-medium">{t("promo_code.rules.bonuses")}</h3>
                <div className="flex flex-col gap-2 text-gray-700 text-sm">
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.100_bonus")}</span> {t("promo_code.rules.100_bonus_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.150_bonus")}</span> {t("promo_code.rules.150_bonus_description")}
                  </p>
                  <p>
                    <span className="font-semibold">{t("promo_code.rules.300_bonus")}</span> {t("promo_code.rules.300_bonus_description")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col space-y-3">
            <span className="text-xl text-slate-700 font-semibold">{t("promo_code.follow")}</span>
            <ReferralsTable organizations={referrals} />
          </div>
          <div className="mt-8 flex-row flex gap-1 w-full items-center justify-start hover:underline hover:cursor-pointer" onClick={() => setOpen(true)}>
            <span className="">{t("promo_code.read_cgv")}</span>
            <FaArrowRight size={12} />
          </div>
        </div>
        <ReferralCGVModal open={open} setOpen={setOpen} onClick={acceptReferralCGV} />
      </div>
    </div>
  );
};

const ReferralCGVModal = ({ open, setOpen, onClick }) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={open} onClose={() => setOpen(!open)} className="flex w-[60vw] max-h-full">
      <div className="w-full h-full px-8 py-4 bg-white rounded-xl">
        <div className="flex items-center justify-center gap-2 mb-4">
          <img src="/favicon.png" alt="finotor" className="w-10 h-10 aspect-square" />
          <h1 className="text-2xl font-bold">{t("promo_code.cgv")}</h1>
        </div>

        <div className="space-y-4 text-gray-700 leading-relaxed">
          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.pre_title")}</h2>
          <p>{t("promo_code.cgv.pre_paragraph_1")}</p>
          <p>{t("promo_code.cgv.pre_paragraph_2")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.eligibility_title")}</h2>
          <p>{t("promo_code.cgv.eligibility_paragraph")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.object_title")}</h2>
          <p>{t("promo_code.cgv.object_paragraph")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.compensation_title")}</h2>
          <p>{t("promo_code.cgv.compensation_paragraph_1")}</p>

          <div className="ml-4">
            <h3 className="text-lg font-semibold text-gray-900">{t("promo_code.cgv.compensation_paragraph_2_title")}</h3>
            <ul className="list-disc ml-6 space-y-2">
              <li>{t("promo_code.cgv.compensation_paragraph_2_item_1")}</li>
              <li>{t("promo_code.cgv.compensation_paragraph_2_item_2")}</li>
              <li>{t("promo_code.cgv.compensation_paragraph_2_item_3")}</li>
            </ul>

            <h3 className="text-lg font-semibold mt-4 text-gray-900">{t("promo_code.cgv.compensation_paragraph_3_title")}</h3>
            <p>{t("promo_code.cgv.compensation_paragraph_3")}</p>

            <h3 className="text-lg font-semibold mt-4 text-gray-900">{t("promo_code.cgv.compensation_paragraph_4_title")}</h3>
            <p>{t("promo_code.cgv.compensation_paragraph_4")}</p>

            <h3 className="text-lg font-semibold mt-4 text-gray-900">{t("promo_code.cgv.compensation_paragraph_5_title")}</h3>
            <h4 className="font-semibold mt-4 text-gray-900">{t("promo_code.cgv.compensation_paragraph_5_subtitle_1")}</h4>
            <ul className="list-disc ml-6 space-y-2">
              <li>{t("promo_code.cgv.compensation_paragraph_5_subtitle_1_item_1")}</li>
              <li>{t("promo_code.cgv.compensation_paragraph_5_subtitle_1_item_2")}</li>
            </ul>
            <h4 className="font-semibold mt-4 text-gray-900">{t("promo_code.cgv.compensation_paragraph_5_subtitle_2")}</h4>
            <ul className="list-disc ml-6 space-y-2">
              <li>{t("promo_code.cgv.compensation_paragraph_5_subtitle_2_item_1")}</li>
            </ul>
          </div>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.payment_title")}</h2>
          <p>{t("promo_code.cgv.payment_paragraph_1")}</p>
          <p>{t("promo_code.cgv.payment_paragraph_2")}</p>
          <p>{t("promo_code.cgv.payment_paragraph_3")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.obligations_title")}</h2>
          <h3 className="text-lg font-semibold mt-4 text-gray-900">{t("promo_code.cgv.obligations_subtitle")}</h3>
          <ul className="list-disc ml-6 space-y-2">
            {t("promo_code.cgv.obligations_list", { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>{t("promo_code.cgv.obligations_warning")}</p>

          <h3 className="text-lg font-semibold mt-4 text-gray-900">{t("promo_code.cgv.dashboard_title")}</h3>
          <p>{t("promo_code.cgv.dashboard_paragraph_1")}</p>
          <p>{t("promo_code.cgv.dashboard_paragraph_2")}</p>

          <h3 className="text-lg font-semibold mt-4 text-gray-900">{t("promo_code.cgv.abuse_title")}</h3>
          <p>{t("promo_code.cgv.abuse_paragraph")}</p>
          <ul className="list-disc ml-6 space-y-2">
            {t("promo_code.cgv.abuse_list", { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>{t("promo_code.cgv.abuse_consequences")}</p>
          <ul className="list-disc ml-6 space-y-2">
            {t("promo_code.cgv.abuse_consequences_list", { returnObjects: true }).map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
          <p>{t("promo_code.cgv.abuse_final")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.terms_update_title")}</h2>
          <p>{t("promo_code.cgv.terms_update_paragraph_1")}</p>
          <p>{t("promo_code.cgv.terms_update_paragraph_2")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.termination_title")}</h2>
          <p>{t("promo_code.cgv.termination_paragraph")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.intellectual_property_title")}</h2>
          <p>{t("promo_code.cgv.intellectual_property_paragraph")}</p>

          <h3 className="text-lg font-semibold mt-4 text-gray-900">{t("promo_code.cgv.intellectual_property_commitment_title")}</h3>
          <p>{t("promo_code.cgv.intellectual_property_commitment_paragraph_1")}</p>
          <p>{t("promo_code.cgv.intellectual_property_commitment_paragraph_2")}</p>
          <p>{t("promo_code.cgv.intellectual_property_commitment_paragraph_3")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.liability_title")}</h2>
          <p>{t("promo_code.cgv.liability_paragraph")}</p>

          <h2 className="text-xl font-semibold text-gray-900 mt-6">{t("promo_code.cgv.jurisdiction_title")}</h2>
          <p>{t("promo_code.cgv.jurisdiction_paragraph")}</p>
        </div>

        <div className="flex justify-end gap-4 mt-6">
          <button className="btn-primary" onClick={onClick}>
            {t("promo_code.button_confirm_cgv")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default List;
