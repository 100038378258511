import React, { useState } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

const MultipleSelectInput = ({ value, label = "", placeholder = "", onChange, className = "", inputClassName = "border border-gray-300 hover:border-sky-700 rounded-md" }) => {
  const [inputValue, setInputValue] = useState("");

  React.useEffect(() => {
    setInputValue("");
  }, [value]);

  return (
    <div className={className}>
      {label ? <div className="px-1 text-sm text-gray-600 font-medium">{label}</div> : null}
      <div className={`relative ${label ? "mt-2" : ""}`}>
        <TagsInput
          className=""
          inputProps={{
            placeholder: placeholder,
            className: `py-1 px-3 bg-white w-full ${inputClassName}`,
            onBlur: () => {
              if (inputValue) {
                onChange([...value, inputValue]);
                setInputValue("");
              }
            },
          }}
          renderLayout={(tagComponents, inputComponent) => (
            <div className="flex flex-col">
              {inputComponent}
              <div className="flex flex-wrap items-center gap-2 mt-2">{tagComponents}</div>
            </div>
          )}
          renderTag={({ tag, key }) => (
            <button
              key={key}
              onClick={() => onChange(value.filter((e) => e !== tag))}
              type="button"
              className="inline-flex items-center px-2 py-1 rounded-full bg-gray-200 text-primary text-sm hover:underline cursor-pointer">
              {tag}
              <span className="ml-1 text-gray-600 hover:text-gray-800">&times;</span>
            </button>
          )}
          value={value}
          onChange={onChange}
          inputValue={inputValue}
          onChangeInput={setInputValue}
        />
      </div>
    </div>
  );
};

export default MultipleSelectInput;
