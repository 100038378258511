import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

import api from "@services/api";

import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import Editor from "./editor";
import Viewer from "./viewer";

const View = () => {
  const { t } = useTranslation();
  const [invoice, setInvoice] = useState(null);
  const { id } = useParams();

  async function get() {
    try {
      const { data: invoiceData } = await api.get(`/invoice/${id}`);
      setInvoice(invoiceData);
    } catch (error) {
      console.error(t("toast.error"), error);
      toast.error(error.code);
    }
  }

  useEffect(() => {
    get();
  }, []);

  if (!invoice) return <Loader />;

  if (invoice.status === "DRAFT") return <Editor invoice={invoice} onFinish={() => get()} />;

  return <Viewer invoice={invoice} />;
};

export default View;
