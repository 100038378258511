import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import api from "@services/api";
import Modal from "@components/utils/Modal";
import { IoCloseCircleSharp } from "react-icons/io5";

import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";
import { copyToClipboard } from "../../../utils/index";

import PaymentLinkLogo from "@assets/payment-link-logo.png";
import Waiting from "@assets/payment-link-waiting.png";
import ActionRequired from "@assets/action-required-logo.png";

import { ENVIRONMENT } from "../../../config";
import { RxCross1 } from "react-icons/rx";
import { FaRegCheckCircle } from "react-icons/fa";

const PaymentModal = ({ isOpen, onClose, invoice, setInvoice, onUpdate }) => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const [gocardlessStatus, setGocardlessStatus] = useState("not_connected");
  const [paymentDetails, setPaymentDetails] = useState({
    amount: invoice.totalTaxIncluded || "0",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (organization?.gocardlessAccessToken) getGocardlessAccountStatus();
    setLoading(false);
  }, []);

  const getGocardlessAccountStatus = async () => {
    try {
      const { data } = await api.get(`/gocardless/status/${organization._id}`);
      setGocardlessStatus(data.creditor.verification_status);
    } catch (error) {
      console.error("Error fetching Gocardless account status:", error);
    }
  };

  const redirectGocardless = async () => {
    try {
      Mixpanel.track(MIXPANEL_EVENTS.btn_connect_gocardless_invoice);
      setLoading(true);
      const { data } = await api.post("/gocardless/connect", {
        invoiceId: invoice._id,
      });
      if (!data.link) return toast.error(t("invoices.gocardless.error_no_link"));
      window.location.href = data.link;
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };

  const generatePaymentLink = async () => {
    try {
      Mixpanel.track(MIXPANEL_EVENTS.btn_generate_payment_link_gocardless_invoice);
      if (paymentDetails.amount <= 0) return toast.error(t("invoices.gocardless.amount_required"));

      setLoading(true);

      const { ok, data } = await api.post("/gocardless/generate-payment-link", {
        organizationId: organization._id,
        paymentDetails: {
          amount: Math.round(parseFloat(paymentDetails.amount) * 100),
          description: paymentDetails.description,
        },
      });
      if (!ok) return toast.error(t("invoices.gocardless.error_generating_link"));
      setInvoice({ ...invoice, paymentLink: data.paymentLink });
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };

  const savePaymentLink = async () => {
    try {
      setLoading(true);
      onUpdate();
      onClose();
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la connexion à GoCardless:", error);
    }
  };
  if (loading) {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="h-full flex flex-col justify-center items-center min-h-[30vh] relative">
          <Loader />
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "not_connected") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="flex flex-col py-8 px-8">
          <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
            <RxCross1 className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-bold mb-4">{t("invoices.gocardless.modal_title")}</h1>
          <p className="text-gray-500 font-light text-sm mb-6">{t("invoices.gocardless.modal_subtitle")}</p>
          <div className="flex justify-center items-center gap-20">
            <div className="flex-shrink-0">
              <img src={PaymentLinkLogo} alt="Payment link illustration" className="w-72" />
            </div>
            <div className="flex flex-col">
              <h2 className="text-base text-gray-600 font-thin mb-4">{t("invoices.gocardless.benefits_title")}</h2>
              <div className="space-y-3 text-gray-600 mb-8">
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("invoices.gocardless.benefit_immediate_payment")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("invoices.gocardless.benefit_no_waiting")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("invoices.gocardless.benefit_faster_process")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("invoices.gocardless.benefit_cash_flow")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("invoices.gocardless.benefit_security")}</span>
                </div>
                <div className="flex items-center gap-2">
                  <CheckIcon />
                  <span className="text-sm font-medium">{t("invoices.gocardless.benefit_powered_by")}</span>
                </div>
              </div>
              <p className="text-blue-500 mb-8">{t("invoices.gocardless.activation_message")}</p>
            </div>
          </div>
          <div className="flex justify-end w-full">
            <button className="btn-primary px-20" onClick={redirectGocardless}>
              {t("invoices.gocardless.btn_lets_go")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "action_required") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="flex flex-col py-8 px-8">
          <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
            <RxCross1 className="w-6 h-6" />
          </button>
          <h1 className="text-2xl font-bold mb-4">{t("invoices.gocardless.action_required")}</h1>
          <p className="text-gray-500 font-light text-sm mb-6">{t("invoices.gocardless.action_required_description")}</p>
          <div className="flex justify-center items-center gap-8 px-4">
            <img src={ActionRequired} alt="Illustration action requise" className="w-48 ml-12" />
          </div>
          <div className="flex justify-end w-full mt-8">
            <button
              className="btn-primary px-10 py-3"
              onClick={() => {
                Mixpanel.track(MIXPANEL_EVENTS.btn_action_required_gocardless_invoice);
                const url = ENVIRONMENT === "development" ? "https://verify-sandbox.gocardless.com" : "https://verify.gocardless.com";
                window.location.href = url;
              }}>
              {t("invoices.gocardless.btn_goto")}
            </button>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "in_review") {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="flex flex-col justify-center py-8 px-8 min-h-[50vh]">
          <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
            <RxCross1 className="w-6 h-6" />
          </button>

          <h1 className="text-2xl font-bold mb-4">{t("invoices.gocardless.in_review")}</h1>
          <p className="text-gray-500 font-light text-sm mb-6">{t("invoices.gocardless.in_review_message")}</p>
          <div className="flex flex-col items-center gap-4">
            <img src={Waiting} alt="Illustration action requise" className="w-36" />
            <p className="text-gray-500 text-center font-light text-sm mt-4 w-1/2">{t("invoices.gocardless.in_review_additional_info")}</p>
            <p className="text-blue-500 mb-8">{t("invoices.gocardless.delay_message")}</p>
          </div>
        </div>
      </Modal>
    );
  }

  if (gocardlessStatus === "successful" && invoice.paymentLink) {
    return (
      <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
        <div className="flex flex-col justify-center py-8 px-8 min-h-[50vh]">
          <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
            <RxCross1 className="w-6 h-6" />
          </button>
          <div className="flex flex-col items-center gap-4">
            <FaRegCheckCircle className="w-12 h-12 text-green-500 mb-8" />
            <div className="flex flex-col items-center gap-2">
              <h2 className="text-2xl font-bold mb-2">{t("invoices.gocardless.payment_link_created")}</h2>
              <p className="text-gray-500 mb-6">{t("invoices.gocardless.share_link_message")}</p>
            </div>
            <div className="w-full flex justify-between items-center border border-gray-300 rounded-lg p-2 mt-4 mb-12">
              <input type="text" value={invoice.paymentLink} readOnly className="w-full bg-transparent focus:outline-none" />
              <button
                type="button"
                onClick={() => {
                  copyToClipboard(invoice.paymentLink);
                  toast.success(t("invoices.gocardless.link_copied"));
                }}
                className="btn-primary">
                {t("invoices.gocardless.copy_link")}
              </button>
            </div>
            <div className="flex justify-between w-full gap-4">
              <button className="btn-secondary" onClick={() => setInvoice({ ...invoice, paymentLink: null })}>
                {t("invoices.editor.regenerate_link")}
              </button>
              <button className="btn-primary" onClick={savePaymentLink}>
                {t("invoices.gocardless.save_payment_link")}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} className={"w-2/3"} innerClassName="w-full" onClose={onClose}>
      <div className="flex flex-col justify-center py-8 px-8 min-h-[50vh]">
        <button onClick={onClose} className="absolute p-2 top-2 right-2 overflow-hidden">
          <RxCross1 className="w-6 h-6" />
        </button>
        <h2 className="text-lg font-semibold">{t("invoices.gocardless.create_payment_link")}</h2>
        <div className="flex flex-col items-center gap-4 mt-5">
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="price-input" className="text-sm font-semibold">
              {t("invoices.gocardless.amount")}
            </label>
            <input
              id="price-input"
              type="text"
              className="text-left rounded-md border-[1px] bg-[#ffffff] disabled:bg-gray-100 border-gray-200 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 md:text-sm placeholder:text-gray-300 py-2 px-2"
              value={paymentDetails.amount}
              onChange={(e) => {
                const newValue = e.target.value;

                if (/^\d*\.?\d{0,2}$/.test(newValue)) {
                  setPaymentDetails({ ...paymentDetails, amount: newValue });
                }
              }}
              placeholder="0.00"
            />
          </div>
          <div className="w-full flex flex-col gap-2">
            <label htmlFor="price-input" className="text-sm font-semibold">
              {t("invoices.gocardless.description")}
            </label>
            <textarea
              type="text"
              value={paymentDetails.description}
              onChange={(e) => {
                setPaymentDetails({ ...paymentDetails, description: e.target.value });
              }}
              className="w-full min-h-[120px] p-3 border border-gray-200 rounded-lg 
                   text-gray-700 placeholder:text-gray-400
                   resize-none"
              placeholder={t("invoices.gocardless.description_placeholder")}
              rows={5}
            />
          </div>
        </div>
        <div className="flex justify-end w-full mt-8">
          <button className="btn-primary px-10 py-3" onClick={generatePaymentLink} disabled={!paymentDetails.amount || !paymentDetails.description}>
            {t("invoices.gocardless.btn_generate_link")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const CheckIcon = () => {
  return (
    <div className="bg-blue-50 rounded-full p-1">
      <svg className="w-4 h-4 text-blue-500" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
    </div>
  );
};

export default PaymentModal;
