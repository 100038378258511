import { useState, useEffect } from "react";
import toast from "react-hot-toast";

import api from "@services/api";
import MultipleSelectInput from "@components/utils/MultipleSelectInput";
import Modal from "@components/utils/Modal";

import { IoCloseCircleSharp } from "react-icons/io5";
import FileInput from "@components/utils/FileInput";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Mixpanel } from "../../../services/mixpanel";
import { MIXPANEL_EVENTS } from "../../../utils/mixpanelEvents";
import { copyToClipboard } from "../../../utils/index";

import { RxCross1 } from "react-icons/rx";

const containsSuspiciousPatterns = (text) => {
  const suspiciousPatterns = [
    /<script/i,
    /javascript:/i,
    /onclick/i,
    /data:/i,
    /about:/i,
    /file:/i,
    /document.cookie/i,
    /onerror=/i,
    /eval\(/i,
    /base64/i,
    /href=/i,
    /url\(/i,
    /vbscript:/i,
    /onload=/i,
    /onmouseover=/i,
    /onfocus=/i,
    /onblur=/i,
    /onkeyup=/i,
    /onkeydown=/i,
    /onkeypress=/i,
    /onmouseout=/i,
    /onsubmit=/i,
    /onreset=/i,
    /onselect=/i,
    /onabort=/i,
    /ondblclick=/i,
    /oncontextmenu=/i,
    /onmousedown=/i,
    /onmousemove=/i,
    /onmouseup=/i,
    /onresize=/i,
    /onscroll=/i,
    /onunload=/i,
    /fromCharCode/i,
    /iframe/i,
    /object/i,
    /embed/i,
    /alert\(/i,
    /confirm\(/i,
    /prompt\(/i,
  ];
  return suspiciousPatterns.some((pattern) => pattern.test(text));
};

const SendModal = ({ isOpen, invoice, setInvoice, onDone, setOpenPaymentModal, setOpenSendModal }) => {
  const { t } = useTranslation();
  const [showCcRecipients, setShowCcRecipients] = useState(true);
  const [showBccRecipients, setShowBccRecipients] = useState(true);
  const user = useSelector((state) => state.Auth.user);
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!isOpen) return;
    if (!invoice.ccRecipients || invoice.ccRecipients?.length === 0) setShowCcRecipients(false);
    if (!invoice.bccRecipients || invoice.bccRecipients?.length === 0) setShowBccRecipients(false);
    if (invoice.memoFiles) setFiles(invoice.memoFiles);
  }, [isOpen]);

  async function sendInvoice(e) {
    try {
      e.preventDefault();
      if (!invoice.recipients.length) return toast.error(t("invoices.editor.error_no_recipients"));
      if (!invoice.emailSubject) return toast.error(t("invoices.editor.error_no_subject"));
      if (!invoice.messageBody) return toast.error(t("invoices.editor.error_no_message_body"));

      if (containsSuspiciousPatterns(invoice.messageBody)) {
        toast.error(t("toast.error.invalid_email_content"));
        return;
      }

      if (!window.confirm(t("confirm_message.mail"))) {
        return;
      }
      const submittedValues = {
        name: invoice.emailSubject,
        messageBody: invoice.messageBody,
        recipients: invoice.recipients,
        ccRecipients: [...(invoice?.ccRecipients || []), user.email],
        bccRecipients: invoice?.bccRecipients,
        includeInvoiceFile: invoice.includeInvoiceFile,
        attachment: [],
      };
      if (invoice.includeInvoiceFile && invoice.file) {
        submittedValues.attachment = [...submittedValues.attachment, { url: invoice.file, name: `invoice_${invoice.reference}.pdf` }];
      }
      if (files.length > 0) {
        submittedValues.attachment = [...submittedValues.attachment, ...files.map((file) => ({ url: file, name: file.split("/").pop() }))];
      }

      const { ok, code } = await api.put(`/invoice/send/${invoice._id}`, submittedValues);
      if (!ok) throw new Error(code);

      toast.success(t("invoices.sent"));
      onDone();
      setOpenSendModal(false);
    } catch (error) {
      console.log(error);
      if (error.message === "MAX_SENDS_REACHED") return toast.error(t("toast.error.invoice.sending_limit_reached"));
      toast.error(t("toast.error.invoice.sending"));
    }
  }

  return (
    <Modal isOpen={isOpen} className={"w-3/5"} innerClassName="w-full h-[calc(100vh-2rem)] max-h-[calc(100vh-2rem)]" onClose={() => setOpenSendModal(false)}>
      <div className="flex flex-col gap-2 w-full h-full overflow-y-hidden transform rounded-lg bg-white text-left align-middle shadow-xl transition-all">
        <div className="px-8 pt-8 pb-3 border-b flex justify-between">
          <h2 className="text-2xl font-semibold text-gray-800">{t("invoices.viewer.send_invoice")}</h2>
          <button type="button" onClick={() => setOpenSendModal(false)}>
            <RxCross1 />
          </button>
        </div>
        <form onSubmit={sendInvoice} className="p-4 overflow-auto">
          <div className="flex flex-col">
            <div className="text-xs text-gray-500 pb-1">{t("invoices.editor.recipients")}</div>
            <div className="flex items-start">
              <div className="flex-1">
                <MultipleSelectInput
                  value={invoice.recipients ?? []}
                  inputClassName="border-y border-l rounded-l-md"
                  placeholder={t("invoices.editor.recipients_placeholder")}
                  onChange={(e) => setInvoice({ ...invoice, recipients: e.map((e) => e.trim()) })}
                />
              </div>
              <div className="flex border-y border-r rounded-r-md">
                <button
                  type="button"
                  className={`px-3 py-1 ${showCcRecipients ? "bg-gray-200 text-gray-700" : "text-gray-500 hover:bg-gray-100"}`}
                  onClick={() => setShowCcRecipients(!showCcRecipients)}>
                  {t("invoices.editor.add_copy_recipients")}
                </button>
                <button
                  type="button"
                  className={`px-3 py-1 ${showBccRecipients ? "bg-gray-200 text-gray-700" : "text-gray-500 hover:bg-gray-100"}`}
                  onClick={() => setShowBccRecipients(!showBccRecipients)}>
                  {t("invoices.editor.add_bcc_recipients")}
                </button>
              </div>
            </div>
          </div>
          {Boolean(showCcRecipients) && (
            <div className="flex flex-col items-start mt-2">
              <div className="text-xs text-gray-500 pb-1">{t("invoices.editor.copy_recipients")}</div>
              <MultipleSelectInput
                className="w-full"
                value={invoice.ccRecipients ?? []}
                inputClassName="border rounded-md"
                placeholder={t("invoices.editor.copy_recipients_placeholder")}
                onChange={(e) => setInvoice({ ...invoice, ccRecipients: e.map((e) => e.trim()) })}
              />
            </div>
          )}
          {Boolean(showBccRecipients) && (
            <div className="flex flex-col items-start mt-2">
              <div className="text-xs text-gray-500 pb-1">{t("invoices.editor.bcc_recipients")}</div>
              <MultipleSelectInput
                className="w-full"
                value={invoice.bccRecipients ?? []}
                inputClassName="border rounded-md"
                placeholder={t("invoices.editor.bcc_recipients_placeholder")}
                onChange={(e) => setInvoice({ ...invoice, bccRecipients: e.map((e) => e.trim()) })}
              />
            </div>
          )}

          <div className="mt-2 flex flex-col">
            <div className="text-xs text-gray-500 pb-1">{t("invoices.subject")}</div>
            <input
              className="w-full rounded-md border bg-[#ffffff] disabled:bg-gray-100 border-gray-200 md:text-sm placeholder:text-gray-300 py-2 px-2"
              name="subject"
              value={invoice.emailSubject ?? ""}
              onChange={(e) => setInvoice({ ...invoice, emailSubject: e.target.value })}
            />
          </div>

          <PaymentLinkContainer invoice={invoice} setOpenPaymentModal={setOpenPaymentModal} setOpenSendModal={setOpenSendModal} />

          <div className="mt-3 text-xs text-gray-500 pb-1">{t("invoices.editor.message_body")}</div>
          <textarea
            className="w-full rounded-md border bg-[#ffffff] disabled:bg-gray-100 border-gray-200 md:text-sm placeholder:text-gray-300 py-2 px-2"
            rows="12"
            name="description"
            value={invoice.messageBody ?? ""}
            onChange={(e) => {
              setInvoice({ ...invoice, messageBody: e.target.value });
              if (e.target.value.length > 10000) {
                toast.error(t("error.invalid_email_content"));
              }
            }}
          />
          <div className="flex flex-col items-start">
            <div className="px-1 text-sm text-gray-600 font-medium">{t("invoices.editor.attachments")} :</div>
          </div>
          <div className="flex flex-col items-start">
            <div className="flex flex-row items-center w-full">
              <FileInput
                onChange={async (e) => {
                  console.log("✌️  file", e.target.value);
                  if (!e.target.value) return;
                  toast.success(t("toast.sucess.created"));
                  setFiles([...files, e.target.value]);
                }}
                name={invoice._id}
                folder={`/invoice/${invoice._id}/attachments`}
                width="w-1/3"
              />
            </div>
          </div>
          {invoice.file ? (
            <div className="flex gap-8">
              <div className="flex justify-between items-center mt-2 gap-2">
                <label htmlFor="hasShippingAddress" className="inline-flex text-primary text-sm">
                  {t("invoices.editor.include")}&nbsp;
                  <a className="underline" href={invoice?.file} target="_blank" rel="noreferrer">
                    {t("invoices.editor.invoice_file")}
                  </a>
                </label>
                <div className="flex items-center gap-2">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={invoice.includeInvoiceFile ?? true}
                      onChange={(e) => {
                        setInvoice({ ...invoice, includeInvoiceFile: e.target.checked });
                      }}
                      className="sr-only peer"
                      name="hasShippingAddress"
                      id="hasShippingAddress"
                    />
                    <div className="w-9 h-5 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-400" />
                  </label>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-sm text-red-500">{t("invoices.editor.no_invoice_file_error")}</div>
          )}
          <div className="flex flex-wrap gap-2 mt-2">
            {files.length > 0 &&
              files.map((file) => (
                <div className="flex items-center gap-3 bg-gray-100 rounded-lg p-2">
                  <a className="text-sm text-blue-500 hover:underline" href={file} target="_blank" rel="noreferrer" key={file}>
                    {file.split("/").pop()}
                  </a>
                  <button onClick={() => setFiles((prev) => prev.filter((f) => f !== file))}>
                    <IoCloseCircleSharp />
                  </button>
                </div>
              ))}
          </div>
          <div className="flex justify-end mt-4 gap-6">
            <button type="submit" className="btn-primary" onClick={() => Mixpanel.track(MIXPANEL_EVENTS.btn_send_invoice)}>
              {t("invoices.viewer.btn_send_invoice")}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

const PaymentLinkContainer = ({ invoice, setOpenPaymentModal, setOpenSendModal }) => {
  const { t } = useTranslation();

  if (!invoice.paymentLink) {
    return (
      <div className="bg-blue-100 p-4 rounded-lg mt-2">
        <h3 className="font-semibold text-base mb-4">{t("invoices.payment_link.title")}</h3>
        <div className="space-y-3 text-gray-600 text-sm mb-4">
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span>{t("invoices.payment_link.benefit1")}</span>
          </div>
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span>{t("invoices.payment_link.benefit2")}</span>
          </div>
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span>{t("invoices.payment_link.benefit3")}</span>
          </div>
          <div className="flex items-center gap-2">
            <CheckIcon />
            <span>{t("invoices.payment_link.benefit4")}</span>
          </div>
        </div>
        <div className="flex justify-center w-full">
          <button
            className="py-2 px-4 border border-black text-black rounded-lg transition-colors"
            type="button"
            onClick={() => {
              Mixpanel.track(MIXPANEL_EVENTS.btn_connect_gocardless_invoice);
              setOpenSendModal(false);
              setOpenPaymentModal(true);
            }}>
            {t("invoices.payment_link.cta")}
          </button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="text-xs text-gray-500 pb-1">{t("invoices.paymentLink")}</div>
      <div className="w-full flex justify-between gap-2 text-black text-sm items-center border border-gray-200 p-1 rounded-lg">
        <a href={invoice.paymentLink} target="_blank" rel="noopener noreferrer" className=" hover:underline">
          {invoice.paymentLink}
        </a>
        <button
          type="button"
          onClick={() => {
            copyToClipboard(invoice.paymentLink);
            toast.success(t("invoices.gocardless.link_copied"));
          }}
          className="btn-primary">
          {t("invoices.gocardless.copy_link")}
        </button>
      </div>
      <div className="bg-blue-100 p-4 rounded-lg text-sm mt-2">{t("invoices.payment_link.note")}</div>
    </>
  );
};

const CheckIcon = () => {
  return (
    <div className="bg-blue-50 rounded-full p-0.5">
      <svg className="w-4 h-4 text-blue-400" viewBox="0 0 20 20" fill="currentColor">
        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
      </svg>
    </div>
  );
};

export default SendModal;
