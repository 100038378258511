import { t } from "i18next";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}
export function currencyToStr(currency) {
  if (!currency) return "";
  switch (currency) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    case "GBP":
      return "£";
    default:
      return currency;
  }
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function getClientName(client) {
  if (!client) return "";
  if (client.entityType === "COMPANY") return client.companyName;
  return `${client.firstname} ${client.lastname}`;
}

export function getTaxRate(taxType) {
  if (taxType == "FR 20%") return 0.2;
  if (taxType == "NL 21%") return 0.21;
  if (taxType == "IE 23%") return 0.23;
  return 0;
}
export const copyToClipboard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    return navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "absolute";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();
    return new Promise((res, rej) => {
      // here the magic happens
      document.execCommand("copy") ? res() : rej();
      textArea.remove();
    });
  }
};

export function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function getTransactionAccountName(transaction) {
  if (!transaction) return "";
  if (transaction.accountDetails === "petty_cash") return t("petty_cash");
  const name = `${transaction.accountDetails ?? ""} ${transaction.accountOwner ?? ""}`.trim();
  if (name === "" || name === "NOT PROVIDED") return transaction.bankName;
  return name;
}
export function getAccountName(account) {
  if (!account) return "";
  if (account.details === "petty_cash") return t("petty_cash");
  const name = `${account.details ?? ""} ${account.ownerName ?? ""}`.trim();
  if (name === "" || name === "NOT PROVIDED") return account?.Requisition?.NordigenInstitution?.name ?? "";
  return name;
}

export function hexToRgb(hex, opacity = 1) {
  if (!hex) return null;
  const bigint = parseInt(hex.replace("#", ""), 16);
  const res = `rgba(${(bigint >> 16) & 255}, ${(bigint >> 8) & 255}, ${bigint & 255}, ${opacity})`;
  console.log("res: ", res);
  return res;
}
