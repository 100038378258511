import { useTranslation } from "react-i18next";
import { Combobox } from "@components/utils/Combobox";

const InvoiceParameter = ({ invoice, setInvoice }) => {
  return (
    <div className="flex flex-col gap-2">
      <LanguageParameter invoice={invoice} setInvoice={setInvoice} />
      <RecurrenceParameter invoice={invoice} setInvoice={setInvoice} />
    </div>
  );
};

const LanguageParameter = ({ invoice, setInvoice }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-sm mb-1">{t("invoices.editor.language")}</div>
      <Combobox
        value={invoice.language}
        nullable={false}
        options={["EN", "FR"]}
        onChange={(value) => setInvoice({ ...invoice, language: value })}
        placeholder={t("invoices.editor.select_language")}
        getLabel={(option) => option}
      />
    </div>
  );
};

const RecurrenceParameter = ({ invoice, setInvoice }) => {
  const { t } = useTranslation();

  const handleRecurrenceChange = (value) => {
    if (value === "UNIQUE") {
      setInvoice({ ...invoice, type: value, ...{ InvoiceRecurrence: undefined } });
    } else {
      setInvoice({
        ...invoice,
        type: value,
        InvoiceRecurrence: {
          frequency: "MONTHS",
          interval: 1,
          startingAt: new Date(),
        },
      });
    }
  };

  return (
    <>
      <div>
        <div className="text-sm mb-1">{t("invoices.editor.recurrence_title")}</div>
        <Combobox
          value={invoice.type}
          options={["UNIQUE", "RECURRENT"]}
          onChange={(value) => {
            handleRecurrenceChange(value);
          }}
          nullable={false}
          placeholder={t("invoices.editor.select_type_recurrence")}
          getLabel={(option) => (option ? t(`invoices.editor.${option}_type`.toLowerCase()) : null)}
        />
      </div>
      {invoice.type === "RECURRENT" && <RepeatOccurence invoice={invoice} setInvoice={setInvoice} />}
    </>
  );
};

const RepeatOccurence = ({ invoice, setInvoice }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col">
      <div className="text-sm mb-1">{t("invoices.editor.repeat")}</div>
      <div className="flex items-center gap-2 mb-2">
        <input
          type="number"
          className="w-12 border border-gray-300 rounded-md px-3 py-1 text-center"
          value={invoice.InvoiceRecurrence?.interval ?? 0}
          onChange={(e) => {
            setInvoice({
              ...invoice,
              InvoiceRecurrence: { ...invoice.InvoiceRecurrence, interval: e.target.value },
            });
          }}
        />
        <Combobox
          value={invoice.InvoiceRecurrence?.frequency}
          options={["WEEKS", "MONTHS", "YEARS"]}
          onChange={(value) => {
            setInvoice({
              ...invoice,
              InvoiceRecurrence: { ...invoice.InvoiceRecurrence, frequency: value },
            });
          }}
          nullable={false}
          placeholder={t("invoices.editor.select_repeat_frequency")}
          getLabel={(option) => (option ? t(`invoices.editor.${option}_repeat`.toLowerCase()) : null)}
        />
      </div>
      <div className="text-sm mb-1">{t("invoices.editor.starting_date")}</div>
      <input
        onChange={(date) =>
          setInvoice({
            ...invoice,
            InvoiceRecurrence: {
              ...invoice.InvoiceRecurrence,
              startingAt: date.target.value ? new Date(date.target.value) : new Date(),
            },
          })
        }
        type="date"
        value={invoice.InvoiceRecurrence?.startingAt && new Date(invoice.InvoiceRecurrence.startingAt).toISOString().slice(0, 10)}
        placeholder="dd-mm-yyyy"
        className="border border-gray-300 rounded-lg px-2 py-1 w-full"
      />
    </div>
  );
};

export default InvoiceParameter;
