import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import "react-tagsinput/react-tagsinput.css";

import api from "@services/api";
import Loader from "@components/utils/Loader";
import { useTranslation } from "react-i18next";
import FileInput from "../../components/utils/FileInput";
import { useDispatch, useSelector } from "react-redux";
import { setOrganization } from "@redux/auth/actions";
import { ENVIRONMENT } from "../../config";
import { hexToRgb } from "../../utils";
import Divider from "../../components/utils/Divider";
import { LuTrash2 } from "react-icons/lu";

const BRAND_COLORS = {
  RED: "#EE605C",
  CORAL: "#EF727A",
  PINK: "#EE7DAC",
  PURPLE: "#B55DF0",
  BLUE: "#5E90EA",
  LIGHT_BLUE: "#82c9de",
  TURQUOISE: "#58CAD1",
  MINT: "#53CFAC",
  GREEN: "#8DD076",
  YELLOW: "#F5C742",
  ORANGE: "#F59C42",
};

const MyOrganizations = () => {
  const { t } = useTranslation();
  const organization = useSelector((state) => state.Auth.organization);
  const [values, setValues] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    setValues(organization);
  }, [organization]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await api.put(`/organization/${values._id}`, { ...values });
    if (!res.ok) return toast.error(t("toast.error.organization_updating"));
    dispatch(setOrganization(values));
    toast.success(t("toast.sucess.organization_updated"));
  };

  const handleDeleteTax = async (id) => {
    try {
      if (!window.confirm(t("confirm_delete_message"))) return;
      const res = await api.remove(`/tax/${id}`);
      if (!res.ok) return toast.error(t("toast.error.deleting_tax"));
      const taxes = values.Taxes.filter((tax) => tax._id !== id);
      dispatch(setOrganization({ ...values, Taxes: taxes }));
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddTax = async () => {
    try {
      const res = await api.post(`/tax`, { name: "tax", value: 0, description: "" });
      if (!res.ok) return toast.error(t("toast.error.adding_tax"));
      const taxes = values.Taxes;
      taxes.push(res.data.tax);
      dispatch(setOrganization({ ...values, Taxes: taxes }));
    } catch (err) {
      console.log(err);
    }
  };

  if (!values) return <Loader />;

  return (
    <div className="flex flex-col w-full">
      <form className="flex flex-col gap-4 mt-4" onSubmit={handleSubmit}>
        <h2 className="font-semibold text-xl">{values.name}</h2>
        {/* Logo */}
        <div className="grid grid-cols-2">
          <div className="flex flex-col gap-2">
            <h2 className="text-primary text-lg font-semibold">{t("settings.add_logo")}</h2>
            <FileInput value={values.logo} onChange={(e) => setValues({ ...values, logo: e.target.value })} folder={`/organization`} name={`logo-${values._id}`} />
          </div>
          {/* Color */}
          <div className="flex flex-col gap-2">
            <h2 className="text-primary text-lg font-semibold">{t("settings.invoice_color")}</h2>
            <div className="flex flex-wrap gap-2">
              {Object.values(BRAND_COLORS).map((color) => (
                <button
                  key={color}
                  onClick={() => setValues({ ...values, color })}
                  style={{ backgroundColor: hexToRgb(color) }}
                  className="w-8 h-8 rounded-md border-2 border-gray-50 transition-transform hover:scale-110">
                  {(values.color || BRAND_COLORS.LIGHT_BLUE) === color && <span className="text-white text-sm">✔</span>}
                </button>
              ))}
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold">{t("settings.organization_info")}</h2>
          <div className="grid grid-cols-2 gap-4">
            {/* Email */}
            <div className="flex flex-col">
              <label htmlFor="email" className="text-gray-800 text-base mb-1">
                {t("email")}
              </label>
              <input
                id="email"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.email ?? ""}
                onChange={(e) => setValues({ ...values, email: e.target.value })}
                placeholder={t("enter_email")}
              />
            </div>
            {/* IBAN */}
            <div className="flex flex-col">
              <label htmlFor="iban" className="text-gray-800 text-base mb-1">
                {t("iban")}
              </label>
              <input
                id="iban"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.iban ?? ""}
                onChange={(e) => setValues({ ...values, iban: e.target.value })}
                placeholder="What is the organization's IBAN?"
              />
            </div>
            {/* Registration number */}
            <div className="flex flex-col">
              <label htmlFor="registrationNumber" className="text-gray-800 text-base mb-1">
                {t("business_registration_number")}
              </label>
              <input
                id="registrationNumber"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.registrationNumber ?? ""}
                onChange={(e) => setValues({ ...values, registrationNumber: e.target.value })}
                placeholder="What is the organization's registration number? (e.g. SIREN)"
              />
            </div>
            {/* VAT */}
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("vat_number")}</div>
              <input
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.vat ?? ""}
                onChange={(e) => setValues({ ...values, vat: e.target.value })}
                placeholder={t("organization.vat_placeholder")}
              />
            </div>
            {/* Activité */}
            <div className="flex flex-col col-span-2">
              <label htmlFor="activity" className="text-gray-800 text-base mb-1">
                {t("organization.activity")}
              </label>
              <textarea
                id="activity"
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                value={values?.activity ?? ""}
                onChange={(e) => setValues({ ...values, activity: e.target.value })}
                placeholder={t("organization.activity_placeholder")}
              />
            </div>
          </div>
        </div>
        {/* Billing address */}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold"> {t("billing_address")}</h2>
          <div className="grid grid-cols-2 grid-rows-2 gap-4">
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("street")}</div>
              <input
                type="text"
                value={values.billingAddressStreet ?? ""}
                onChange={(e) => setValues({ ...values, billingAddressStreet: e.target.value })}
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.street_placeholder")}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-gray-800 text-base mb-1">{t("city")}</div>
              <input
                type="text"
                value={values.billingAddressCity ?? ""}
                onChange={(e) => setValues({ ...values, billingAddressCity: e.target.value })}
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.city_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-gray-800 text-base mb-1"> {t("zip_code")} </div>
              <input
                type="text"
                value={values.billingAddressZipCode ?? ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    billingAddressZipCode: e.target.value,
                  })
                }
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.zip_placeholder")}
              />
            </div>
            <div className="flex flex-col row-start-2">
              <div className="text-gray-800 text-base mb-1"> {t("country")} </div>
              <input
                type="text"
                value={values.billingAddressCountry ?? ""}
                onChange={(e) =>
                  setValues({
                    ...values,
                    billingAddressCountry: e.target.value,
                  })
                }
                className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                placeholder={t("organization.country_placeholder")}
              />
            </div>
          </div>
        </div>
        <Divider />
        {/* VAT + add new VAT */}
        <div className="w-full flex flex-col gap-2 col-span-2">
          <h2 className="text-primary text-lg font-semibold">{t("vat")}</h2>
          <div className="flex flex-col gap-4">
            {values.Taxes?.map((tax, index) => (
              <div key={index} className="flex items-end w-full gap-4">
                <div className="w-54 flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("name")} </div>
                  <input
                    type="text"
                    value={tax.name ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].name = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                    placeholder={t("name")}
                  />
                </div>
                <div className="w-54 flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("VAT_rate")} </div>
                  <input
                    type="text"
                    value={tax.value ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].value = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                    placeholder={t("VAT_rate")}
                  />
                </div>
                <div className="w-full flex flex-col">
                  <div className="text-gray-800 text-base mb-1"> {t("description")} </div>
                  <input
                    type="text"
                    value={tax.description ?? ""}
                    onChange={(e) => {
                      const taxes = values.Taxes;
                      taxes[index].description = e.target.value;
                      setValues({ ...values, Taxes: taxes });
                    }}
                    className="border-[1px] placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2"
                    placeholder={t("description")}
                  />
                </div>
                <button
                  className="bg-transparent ml-2 font-semibold hover:text-red-500 hover:border-red-500 p-3 text-sm border rounded-md"
                  onClick={() => handleDeleteTax(tax._id)}>
                  <LuTrash2 size={16} />
                </button>
              </div>
            ))}
            <button type="button" onClick={handleAddTax} className="w-fit px-1 text-primary text-base mb-1 font-semibold border-b border-black">
              + {t("add_vat")}
            </button>
          </div>
        </div>
        <Divider />
        {/* Due date */}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold">{t("settings.payment_delay_title")}</h2>
          <label htmlFor="dueDate" className="text-gray-800 text-base mb-1">
            {t("settings.number_of_days")}
          </label>
          <input
            id="dueDate"
            type="number"
            min="0"
            max="120"
            value={values.dueDate ?? 30}
            onChange={(e) => setValues({ ...values, dueDate: e.target.value })}
            className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2 max-w-xs"
            placeholder="Entrez le nombre de jours"
          />
        </div>
        <Divider />
        {/* Subject + body template*/}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold">{t("settings.email_template")}</h2>
          <div className="flex flex-col gap-2">
            <label htmlFor="emailSubject" className="text-gray-800 font-medium">
              {t("settings.email_subject")}
            </label>
            <input
              id="emailSubject"
              type="text"
              value={values.subjectEmailTemplate}
              onChange={(e) => setValues({ ...values, subjectEmailTemplate: e.target.value })}
              placeholder={t("settings.email_subject_placeholder")}
              className="border-[1px]  placeholder:text-gray-300 rounded-md text-gray-800 px-3 py-2 focus:ring-2 focus:ring-primary focus:border-transparent"
            />
          </div>
          <div className="flex flex-col gap-2">
            <label htmlFor="emailBody" className="text-gray-800 font-medium">
              {t("settings.email_body")}
            </label>
            <textarea
              id="emailBody"
              value={values.bodyEmailTemplate}
              onChange={(e) => setValues({ ...values, bodyEmailTemplate: e.target.value })}
              placeholder={t("settings.email_body_placeholder")}
              rows={6}
              className="border-[1px] border-slate-300 placeholder:text-slate-300 rounded-md text-gray-800 px-3 py-2 focus:ring-2 focus:ring-primary focus:border-transparent resize-y"
            />
          </div>
        </div>
        <Divider />
        {/* Terms & conditions */}
        <div className="flex flex-col gap-2">
          <h2 className="text-primary text-lg font-semibold">{t("settings.legal_information")}</h2>
          <div className="flex flex-col gap-2">
            <label htmlFor="terms" className="text-gray-800 font-medium">
              {t("settings.terms_conditions")}
            </label>
            <textarea
              id="terms"
              value={values.termsEmailTemplate}
              onChange={(e) => setValues({ ...values, termsEmailTemplate: e.target.value })}
              placeholder={t("settings.terms_conditions_placeholder")}
              rows={6}
              className="border-[1px] border-slate-300 placeholder:text-slate-300 rounded-md text-gray-800 px-3 py-2 focus:ring-2 focus:ring-primary focus:border-transparent resize-y"
            />
          </div>
        </div>
        <div className="flex mt-4 w-full">
          <button type="submit" className="btn-primary">
            {t("save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default MyOrganizations;
